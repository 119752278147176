import { FormDataValue } from 'stores/atom/FormData';
import { SelectedAgency } from 'stores/atom/SelectedAgency';
import { SelectedTariffAtom } from 'features/TariffComparison/interfaces';
import { ivId, keyId, oevSessionStorageNamespace } from './Constants';
import { decryptData } from './crypto';
import {
	closeDatabase,
	getStore,
	openDatabase,
	resolveTransaction,
	retrieveKey,
	retrieveUint8Array,
} from './indexedDb';

export interface OevSessionStorage {
	formData?: FormDataValue;
	selectedAgency?: SelectedAgency;
	selectedTariff?: SelectedTariffAtom;
}

export default async function getSessionStorage() {
	if (!window?.sessionStorage) return null;
	const sessionStorageData = window?.sessionStorage?.getItem(oevSessionStorageNamespace);
	if (!sessionStorageData) return null;

	// Base64 encoded string to Uint8Array
	const encryptedDataBuffer = Uint8Array.from(atob(sessionStorageData), (c) => c.charCodeAt(0));
	// Retrieve from IndexedDB
	const db = await openDatabase(); // static IDBDatabase 'oev-db'
	const store = await getStore(db, 'readonly'); // static IDBObjectStore 'oev'
	const iv = await retrieveUint8Array(ivId, store);
	const key = await retrieveKey(keyId, store);
	await resolveTransaction(store?.transaction);
	closeDatabase(db);

	if (!iv || !key) return null;

	const decryptedData = await decryptData(
		key,
		encryptedDataBuffer,
		iv,
	);
	if (!decryptedData) return null;
	// Deserialize to object
	const oevUserSessionData = JSON.parse(decryptedData ?? '{}') as OevSessionStorage;

	return oevUserSessionData;
}
