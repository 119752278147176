// Dexie.js would be an alternative as it's a wrapper around IndexedDB that simplifies its usage
// but also adds bundle size.
/* eslint-disable no-param-reassign */
import { aesGcmAlgorithm, oevIndexedDbStoreNamespace } from './Constants';

/**
 * Utility function to open the IndexedDB.
 *
 * @returns {Promise<IDBDatabase>} A promise that resolves to the opened IndexedDB database.
 */
export async function openDatabase(): Promise<IDBDatabase> {
	return new Promise((resolve, reject) => {
		const request = indexedDB.open('oev-db', 1);

		request.onupgradeneeded = () => {
			const db = request.result;
			db.createObjectStore(oevIndexedDbStoreNamespace, { keyPath: 'id' });
		};

		request.onsuccess = () => {
			resolve(request.result);
		};

		request.onerror = () => {
			reject(request.error);
		};
	});
}

/**
 * Closes the connection to the IndexedDB.
 *
 * @param {IDBDatabase} db - The database connection to close.
 */
export function closeDatabase(db: IDBDatabase): void {
	db.close();
}

/**
 * Utility function to get the oev store from IndexedDB.
 *
 * @param {IDBDatabase} db - The database to get the store from.
 * @param {string} mode - The mode for the transaction ('readonly' or 'readwrite').
 * @returns {Promise<IDBObjectStore>} A promise that resolves to the object store.
 */
export async function getStore(db: IDBDatabase, mode: 'readonly' | 'readwrite'): Promise<IDBObjectStore> {
	const transaction = db?.transaction(oevIndexedDbStoreNamespace, mode);
	const store = transaction?.objectStore(oevIndexedDbStoreNamespace);
	return store;
}

/**
 * Utility function to resolve a IndexedDB transaction.
 *
 * @param {IDBTransaction} transaction - The transaction to be resolved.
 * @returns {Promise<void>} A promise that resolves when the buffer is successfully stored.
 */
export async function resolveTransaction(transaction: IDBTransaction): Promise<void> {
	return new Promise((resolve, reject) => {
		transaction.oncomplete = () => resolve();
		transaction.onerror = () => reject(transaction.error);
	});
}

/**
 * Utility function to store a key in IndexedDB.
 *
 * @param {CryptoKey} key - The cryptographic key to store.
 * @param {IDBObjectStore} store - The object store to use for storing the key.
 * @param {string} keyId - The ID associated with the key.
 */
export async function storeKey(
	key: JsonWebKey,
	store: IDBObjectStore,
	keyId: string,
): Promise<void> {
	store.put({ id: keyId, key });
}

/**
 * Utility function to retrieve a key from IndexedDB.
 *
 * @param {string} keyId - The ID associated with the key.
 * @param {IDBObjectStore} store - The object store to use for storing the key.
 * @returns {Promise<CryptoKey | null>} A promise that resolves to the retrieved cryptographic key,
 * or null if not found.
 */
export async function retrieveKey(keyId: string, store: IDBObjectStore): Promise<CryptoKey | null> {
	return new Promise((resolve, reject) => {
		const request = store.get(keyId);

		request.onsuccess = async () => {
			if (request.result) {
				const importedKey = await crypto.subtle.importKey(
					'jwk',
					request.result.key,
					{ length: 256, name: aesGcmAlgorithm },
					true,
					['encrypt', 'decrypt'],
				);
				resolve(importedKey);
			} else resolve(null);
		};

		request.onerror = () => {
			reject(request.error);
		};
	});
}

/**
 * Utility function to store a Uint8Array in IndexedDB.
 *
 * @param {Uint8Array} data - The Uint8Array to store.
 * @param {IDBObjectStore} store - The object store to use for storing the key.
 * @param {string} bufferId - The ID associated with the buffer.
 */
export async function storeUint8Array(
	data: Uint8Array,
	store: IDBObjectStore,
	bufferId: string,
): Promise<void> {
	store.put({ data, id: bufferId });
}

/**
 * Utility function to retrieve a Uint8Array from IndexedDB.
 *
 * @param {string} bufferId - The ID associated with the buffer.
 * @param {IDBObjectStore} store - The object store to use for storing the key.
 * @returns {Promise<Uint8Array | null>} A promise that resolves to the retrieved Uint8Array,
 * or null if not found.
 */
export async function retrieveUint8Array(
	bufferId: string,
	store: IDBObjectStore,
): Promise<Uint8Array | null> {
	return new Promise((resolve, reject) => {
		const request = store.get(bufferId);

		request.onsuccess = () => {
			if (request.result) resolve(request.result.data);
			else resolve(null);
		};

		request.onerror = () => {
			reject(request.error);
		};
	});
}
