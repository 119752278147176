import SelectedAgencyAtom, { selectedAgencyDefault } from 'stores/atom/SelectedAgency';
import SelectedTariff from 'features/TariffComparison/stores/atom/SelectedTariff';
import UserSessionStorageFormDataAtom from 'stores/atom/UserSessionStorageFormData';
import getSessionStorage from 'utils/formRedirect/getSessionStorage';

export default async function initRecoilStateFromSessionStorage() {
	try {
		const userSessionStorageData = await getSessionStorage();
		if (userSessionStorageData) {
			// set selected agency, tariff and formData to default values from session storage
			const initializeState = ({ set }: { set: (atom: unknown, value: Object) => void; }) => {
				set(SelectedAgencyAtom, userSessionStorageData?.selectedAgency ?? selectedAgencyDefault);
				set(SelectedTariff, userSessionStorageData?.selectedTariff ?? {});
				set(UserSessionStorageFormDataAtom, userSessionStorageData?.formData ?? {});
			};
			return initializeState;
		}
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('Failed to get sessionStorage:', error);
	}
	return () => {};
}
