import { aesGcmAlgorithm } from './Constants';

/**
 * Generate a cryptographic key using the Web Crypto API.
 *
 * @returns {Promise<CryptoKey>} A promise that resolves to the generated CryptoKey.
 */
export async function generateKey(): Promise<CryptoKey> {
	return crypto?.subtle?.generateKey(
		{
			length: 256,
			name: aesGcmAlgorithm,
		},
		true, // Set key as extractable
		['encrypt', 'decrypt'], // Key usages
	);
}

/**
 * Encrypt data using the key parameter.
 *
 * @param {CryptoKey} key - The cryptographic key to use for encryption.
 * @param {string} data - The data to encrypt.
 * @returns {Promise<{ encryptedData: ArrayBuffer; iv: Uint8Array; }>}
 * A promise that resolves to an object containing the encrypted data and the initialization vector.
 */
export async function encryptData(
	key: CryptoKey,
	data: string,
): Promise<{ encryptedData: ArrayBuffer; iv: Uint8Array; }> {
	// Initialization vector with 12 array elements x 8 Bit = 96 Bit as suggested by BSI [1] on p. 43
	// and National Institute of Standards and Technology [2] on page 23
	// [1] https://www.bsi.bund.de/SharedDocs/Downloads/DE/BSI/Publikationen/TechnischeRichtlinien/TR02102/BSI-TR-02102.pdf?__blob=publicationFile&v=10
	// [2] https://nvlpubs.nist.gov/nistpubs/Legacy/SP/nistspecialpublication800-38d.pdf
	const iv = crypto?.getRandomValues(new Uint8Array(12));
	const encodedData = new TextEncoder().encode(data); // Convert the data string to a Uint8Array
	const encryptedData = await crypto?.subtle?.encrypt(
		{
			iv,
			name: aesGcmAlgorithm,
		},
		key,
		encodedData.buffer,
	);
	return { encryptedData, iv };
}

/**
 * Decrypt data using the generated key.
 *
 * @param {CryptoKey} key - The cryptographic key to use for decryption.
 * @param {ArrayBuffer} encryptedData - The encrypted data to decrypt.
 * @param {Uint8Array} iv - The initialization vector used during encryption.
 * @returns {Promise<string>} A promise that resolves to the decrypted data as a string.
 */
export async function decryptData(
	key: CryptoKey,
	encryptedData: ArrayBuffer,
	iv: Uint8Array,
): Promise<string | null> {
	if (!key || !encryptedData || !iv) return Promise.resolve(null);
	try {
		const decryptedDataBuffer = await crypto?.subtle?.decrypt(
			{
				iv,
				name: aesGcmAlgorithm,
			},
			key,
			encryptedData,
		);
		// Convert the decrypted ArrayBuffer back to a string
		return new TextDecoder().decode(decryptedDataBuffer);
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('Error decrypting data: ', error);
		return Promise.resolve(null);
	}
}
