import { ivId, keyId, oevSessionStorageNamespace } from './Constants';
import { encryptData, generateKey } from './crypto';
import { OevSessionStorage } from './getSessionStorage';
import {
	closeDatabase,
	getStore,
	openDatabase,
	resolveTransaction,
	storeKey,
	storeUint8Array,
} from './indexedDb';

export default async function setSessionStorage(data: OevSessionStorage) {
	const key: CryptoKey = await generateKey();
	const serializedData = JSON.stringify(data);
	const { iv, encryptedData } = await encryptData(key, serializedData);
	const exportedKey = await crypto.subtle.exportKey('jwk', key);
	// Base64 encode the ArrayBuffer to string
	const cipherText = btoa(String.fromCharCode(...new Uint8Array(encryptedData)));

	// Store key and iv in IndexedDB
	const db = await openDatabase();
	const store = await getStore(db, 'readwrite');
	await storeUint8Array(iv, store, ivId);
	await storeKey(exportedKey, store, keyId);
	await resolveTransaction(store?.transaction);
	closeDatabase(db);

	window?.sessionStorage?.setItem(
		oevSessionStorageNamespace,
		cipherText,
	);
}
