import { atom } from 'recoil';
import { SelectedTariffAtom } from '../../interfaces';

// initial value can be set from index.tsx based on session storage
const SelectedTariff = atom<SelectedTariffAtom>({
	default: {},
	key: 'SelectedTariff',
});

export default SelectedTariff;
