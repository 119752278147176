// ToDo: technical debt - this state should be in the Agency feature
import { atom } from 'recoil';

export interface SelectedAgency {
	Agenturnummer: string;
	key: string;
	label?: string;
	orderNumber?: number;
	screen: number;
	sectionId?: string;
	type?: string;
	value: {
		Agenturadresse: string;
		Agenturname: string;
	};
}

export const selectedAgencyDefault: SelectedAgency = {
	Agenturnummer: '',
	key: '',
	label: '',
	orderNumber: 0,
	screen: 0,
	sectionId: '',
	type: '',
	value: {
		Agenturadresse: '',
		Agenturname: '',
	},
};

// initial value can be set from index.tsx based on session storage
const SelectedAgencyAtom = atom<SelectedAgency>({
	default: selectedAgencyDefault,
	key: 'SelectedAgency',
});

export default SelectedAgencyAtom;
