import { PageConfigData, PageProductTariff } from 'stores/atom/PageConfig';
import {
	TariffComparisonData,
	TariffComparisonMapped,
	ModuleConfigValue,
	Module,
	TariffComparisonButton,
} from '../interfaces';

export function mapTariffDataModuleConfigsToModuleGroups(tariffData: TariffComparisonData) {
	const flattenedModuleConfigs: { [key: string]: Array<ModuleConfigValue>; } = {};
	const moduleConfigsPerTariff = tariffData.tariffDataDetails.map((tariff) => tariff.moduleConfigs);

	moduleConfigsPerTariff.forEach((tariff) => {
		tariff?.forEach((item) => {
			const { moduleReference, value } = item;
			if (!flattenedModuleConfigs[moduleReference]) flattenedModuleConfigs[moduleReference] = [];
			flattenedModuleConfigs[moduleReference].push(value);
		});
	});

	return tariffData?.moduleGroups?.map((group) => ({
		...group,
		expanded: true,
		modules: group?.modules?.map((module: Module) => ({
			...module,
			value: module?.name ? flattenedModuleConfigs[module?.name] : [],
		})),
	}));
}

export function mapPageConfigButtonsToTariffComparisonButtons(
	buttonsPerTariff: (TariffComparisonButton | undefined)[],
) {
	return buttonsPerTariff.map((button) => {
		if (!button) return;
		const buttonTemp = { ...button } ?? {};
		const href = new URL(
			(button?.type === 'internal' ? button?.internal : button?.external) ?? '',
			button?.type === 'external' ? undefined : window.location.origin,
		);
		if (!href) return buttonTemp;

		button?.parameters?.forEach((paramObj) => Object.entries(paramObj).forEach(([key, value]) => {
			href.searchParams.set(key, value);
		}));
		buttonTemp.href = href?.toString();
		return buttonTemp;
	});
}

export function filterPageConfigButtonsByTariffId(
	id: string,
	pageConfigTariffs: Array<PageProductTariff>,
) {
	if (!id || !pageConfigTariffs.length) return [];
	const buttonsPerTariff = pageConfigTariffs
		.filter((tariff) => tariff.id === id)
		.flatMap((tariff) => tariff.buttons);
	return mapPageConfigButtonsToTariffComparisonButtons(buttonsPerTariff);
}

// ToDO: write tests for the mapping when API is final
export function mapTariffDataToComponent(
	tariffData: TariffComparisonData,
	pageConfig: PageConfigData,
): TariffComparisonMapped {
	const pageConfigTariffs = pageConfig?.product?.tariffs || [];
	return {
		moduleGroups: mapTariffDataModuleConfigsToModuleGroups(tariffData) || [],
		prefix: '', // LS-1588 - prefix shouldn't be displayed when calculating prices
		productName: tariffData?.productName,
		suffix: tariffData?.suffix,
		tariffData: tariffData?.tariffDataDetails
			?.map((tariff) => ({
				anchoring: tariff?.anchoring,
				anchoringSubline: tariff?.anchoringSubline,
				buttons: [
					tariffData?.button,
					...filterPageConfigButtonsByTariffId(tariff?.id ?? '', pageConfigTariffs),
				] as TariffComparisonButton[],
				description: tariff?.description,
				highlighted: tariff?.highlighted === 'true',
				id: tariff?.id,
				name: tariff?.name,
				overline: tariff?.overline,
				period: tariff?.period,
				premium: tariff?.premium,
				subline: tariff?.subline,
				visible: tariff?.visible,
			})),
	};
}
